import { render, staticRenderFns } from "./favorite.vue?vue&type=template&id=28d4835e&scoped=true"
import script from "./favorite.vue?vue&type=script&lang=js"
export * from "./favorite.vue?vue&type=script&lang=js"
import style0 from "./favorite.vue?vue&type=style&index=0&id=28d4835e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28d4835e",
  null
  
)

export default component.exports